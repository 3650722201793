// extracted by mini-css-extract-plugin
export var button = "sponsor-module--button--9dc50";
export var container_image = "sponsor-module--container_image--3ba6d";
export var container_image_gallery = "sponsor-module--container_image_gallery--b0314";
export var container_image_logo = "sponsor-module--container_image_logo--d9f06";
export var donation = "sponsor-module--donation--eaeda";
export var episodes = "sponsor-module--episodes--21035";
export var gallery_center = "sponsor-module--gallery_center--fcafa";
export var gradient_section = "sponsor-module--gradient_section--9107e";
export var indexButton = "sponsor-module--index-button--bb82c";
export var indexFooter = "sponsor-module--index-footer--c28c6";
export var italic = "sponsor-module--italic--5f056";
export var main = "sponsor-module--main--194aa";
export var mainButton = "sponsor-module--main-button--22a54";
export var mr_5 = "sponsor-module--mr_5--19132";
export var proyectoPortada = "sponsor-module--proyecto-portada--513ab";
export var section_1 = "sponsor-module--section_1--616c6";
export var section_2 = "sponsor-module--section_2--cf762";
export var sponsor_container = "sponsor-module--sponsor_container--4b225";
export var team_image = "sponsor-module--team_image--bdbd3";
export var text_left = "sponsor-module--text_left--ecc21";
export var video02 = "sponsor-module--video02--a5f88";
export var vilchesTerrain = "sponsor-module--vilchesTerrain--0cb65";